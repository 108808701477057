






















































import { Component, Ref, Mixins } from "vue-property-decorator";
import { Option } from "@/graphql/client";
import optionService from "@/service/optionService";
import { NotificationState } from "@/store/modules/notification";
import OptionDeleteDialog from "@/components/organisms/option/OptionDeleteDialog.vue";
import OptionEditDialog from "@/components/organisms/option/OptionEditDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    OptionDeleteDialog,
    OptionEditDialog,
  },
})
export default class OptionList extends Mixins(MixinFormatter) {
  @Ref() readonly editDialog!: OptionEditDialog;
  @Ref() readonly deleteDialog!: OptionDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: Option[] = [];
  readonly headers = [
    {
      text: "名称",
      value: "name",
      width: "30%",
    },
    {
      text: "条件",
      value: "expressionName",
    },
    {
      text: "種別",
      value: "optionType",
      width: "20%",
      formatter: this.getOptionTypeLabel,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  loadingDataGrid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    optionService.allOptions().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }
  /**
   * オプション編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(optionService.defaultOption);
  }

  /**
   * オプション編集ダイアログを表示します.
   */
  public openEditDialog(item: Option): void {
    this.editDialog.open(item);
  }

  /**
   * オプション編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: Option): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("オプションを更新しました！");
  }

  /**
   * オプション編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: Option): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("オプションを登録しました！");
  }

  /**
   * オプション削除ダイアログを表示します.
   */
  public openDeleteDialog(item: Option): void {
    this.deleteDialog.open(item);
  }

  /**
   * オプション削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: Option): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;
 
    this.deleteDialog.close();
    NotificationState.notifySuccess("オプションを削除しました！");
  }
}
