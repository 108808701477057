
































































































































import { Option, OptionItem, OptionType } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import optionService from "@/service/optionService";
import typeService from "@/service/typeService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/option/MixinOptionEditDialogValidator.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class OptionEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Option = optionService.defaultOption;
  optionItems: OptionItem[] = [];
  valid = false;
  optionTypes = typeService.optionTypes;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: Option): Promise<void> {
    this.item = optionService.defaultOption;
    if (item.guid) {
      const option = await optionService.getOption(item.guid);
      if (option) {
        this.item = option;
        if (this.item.optionType) {
          this.optionItems = await optionService.searchOptionItemsByOptionType(
            this.item.optionType
          );
        } else {
          this.optionItems = [];
        }
      }
    }
  }

  async changeOptionType(value: OptionType): Promise<void> {
    console.log("changeOptionType");
    this.item.optionItems = [];
    const items = await optionService.searchOptionItemsByOptionType(value);
    if (items) this.optionItems = items;
  }

  /**
   * ダイアログを表示します.
   */
  public async open(item: Option): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        optionService.updateOption(this.item).then((result) => {
          this.isProgressing = false;
          this.notifyUpdateSuccess(result as Option);
        });
      } else {
        //create
        optionService.createOption(this.item).then((result) => {
          this.isProgressing = false;
          this.notifyCreateSuccess(result as Option);
        });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: Option): Option {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: Option): Option {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(): string {
    return "error!";
  }
}
