










import SettingSubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import OptionList from "@/components/organisms/option/OptionList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    OptionList,
  },
};
